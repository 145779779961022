import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const ApiHeaders = {
  Accept: 'application/json',
};
const baseUrl = 'https://awardtest.techtrend.africa/api';
export interface Categories {
  id: number;
  name: string;
  created_at?: string;
  updated_at?: string;
}
export interface Category {
  id: number;
  date_ending?: {
    date: string;
  };
  linkedin: string;
  name: string;
  votes: string;
  user_image: string;
  category: string;
  sub_category: string;
}
export interface GetCelebratedTechies {
  id: number;
  user_image: string;
}
export interface Search {
  id: number;
  name: string;
  votes?: string;
  user_image?: string;
  category_id?: number;
  created_at?: string;
  updated_at?: string;
  type?: string;
  sub_category_id?: string;
}
export interface Payload {
  name?: string;
  email?: string;
}
interface SearchPayload {
  search: string;
}
interface PricingResponse {
  price: number;
  count: number;
}
export interface PostVote {
  category_id: number;
  nominee_id: number;
  payload: { points: number };
}
interface StatisticsResponse {
  status: boolean;
  message: string;
  data: {
    winners: number;
    nominees: number;
    attestations: number;
  };
}
const createRequest = (
  url: string,
  method: any
): { url: string; headers: Record<string, string>; method: string } => ({
  url,
  headers: ApiHeaders,
  method,
});
const postRequest = (
  url: string,
  method: string,
  body: object
): {
  url: string;
  headers: Record<string, string>;
  method: string;
  body: any;
} => ({
  url,
  headers: ApiHeaders,
  method,
  body,
});
const postRequestWithHeader = (
  url: string,
  method: string,
  headers: any
): { url: string; headers: Record<string, string>; method: string } => ({
  url,
  headers,
  method,
});
const connectedAwardsApi = createApi({
  reducerPath: 'Api',
  baseQuery: fetchBaseQuery({ baseUrl }),
  tagTypes: ['Post', 'Get'],

  endpoints: (builder) => ({
    getCategories: builder.query({
      query: () => createRequest('/categories', 'GET'),
      transformResponse: (response: { data: Categories[] }) => response.data,
      providesTags: ['Get'],
    }),
    getCelebratedTechies: builder.query({
      query: () => createRequest('/celebrated-techies', 'GET'),
      transformResponse: (response: { data: GetCelebratedTechies[] }) =>
        response.data,
      providesTags: ['Get'],
    }),
    getCategory: builder.query({
      query: (id: number) => createRequest(`/category/${id}`, 'GET'),
      transformResponse: (response: { data: Category[] }) => response.data,
      providesTags: ['Get'],
    }),
    getNominee: builder.query({
      query: (id: number) => createRequest(`/nominee/${id}`, 'GET'),
      transformResponse: (response: { data: Category | any }) => response.data,
      providesTags: ['Get'],
    }),
    getCandidate: builder.mutation({
      query: ({
        nominee_id,
        category_id,
      }: {
        nominee_id: number;
        category_id: number;
      }) => createRequest(`/nominee-data/${category_id}/${nominee_id}`, 'GET'),
    }),
    storeUser: builder.query({
      query: (payload: Payload) => postRequest('/store-user', 'POST', payload),
      providesTags: ['Post'],
    }),
    postSearch: builder.mutation({
      query: (payload: SearchPayload) =>
        postRequest('/search', 'POST', payload),
      transformResponse: (response: { data: Search[] }) => response.data,
    }),
    postVote: builder.mutation({
      query: ({ category_id, nominee_id, payload }: PostVote) =>
        postRequest(`/vote/${category_id}/${nominee_id}`, 'POST', payload),
    }),
    getProPricing: builder.query({
      query: () => createRequest('/pro-pricing', 'GET'),
      transformResponse: (response: { data: PricingResponse[] }) =>
        response.data,
      providesTags: ['Post'],
    }),
    getBasicPricing: builder.query({
      query: () => createRequest('/basic-pricing', 'GET'),
      transformResponse: (response: { data: PricingResponse[] }) =>
        response.data,
      providesTags: ['Post'],
    }),
    initializePayment: builder.mutation({
      query: (payload: any) => postRequest('/pay-flutter', 'POST', payload),
    }),
    acceptNomination: builder.mutation({
      query: (payload: FormData) => postRequest('/nominate', 'POST', payload),
    }),
    getWinnersCount: builder.query({
      query: () => createRequest('/statistics', 'GET'),
      transformResponse: (data: StatisticsResponse) => data.data,
    }),
  }),
});
export const {
  useGetCategoriesQuery,
  usePostSearchMutation,
  useGetCategoryQuery,
  useStoreUserQuery,
  usePostVoteMutation,
  useGetBasicPricingQuery,
  useGetProPricingQuery,
  useGetCelebratedTechiesQuery,
  useInitializePaymentMutation,
  useGetNomineeQuery,
  useGetCandidateMutation,
  useAcceptNominationMutation,
  useGetWinnersCountQuery,
} = connectedAwardsApi;
export default connectedAwardsApi;
